import React, { useEffect } from "react";
import "./App.css";
import ToastSnackbar from "./CommonComponents/ToastNotification";
import GloblaContextProvider from "./Context/GloblaContext";
import Index from "./Routes";
import { ThemeProvider, createTheme } from "@mui/material";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallbackComponent from "./CommonComponents/ErrorFallbackComponent";
import { analyticsInitialize } from "./Utils/helper";
import ReactGA from "react-ga";
import { isLoadingStateReducer } from "./Redux/Slice/loginSlice";
import { useDispatch } from "react-redux";
const GTAGKEY = process.env.REACT_APP_GTAG_KEY;
const theme = createTheme({
  typography: {
    fontFamily: "Inter",
  },
  button: {
    fontFamily: "Inter",
    color: "yellow",
  },
});
analyticsInitialize();

export const initGA = () => {
  ReactGA.initialize(GTAGKEY);
};

export const logPageView = () => {
  ReactGA.set({ page: window.location.pathname });
  ReactGA.pageview(window.location.pathname);
};

function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    initGA();
    logPageView();
    dispatch(isLoadingStateReducer(false));
  }, []);

  useEffect(() => {
    // Set a nonce value
    const nonce = process.env.REACT_APP_NONCE;
    // First script: adrum configuration
    const adrumConfigScript = document.createElement("script");
    adrumConfigScript.type = "text/javascript";
    adrumConfigScript.charset = "UTF-8";
    adrumConfigScript.nonce = nonce;
    adrumConfigScript.innerHTML = `
        window["adrum-start-time"] = new Date().getTime();
        (function(config){
            config.appKey = "BO-AAB-FWK";
            config.adrumExtUrlHttp = "http://cdn.appdynamics.com";
            config.adrumExtUrlHttps = "https://cdn.appdynamics.com";
            config.beaconUrlHttp = "http://bom-col.eum-appdynamics.com";
            config.beaconUrlHttps = "https://bom-col.eum-appdynamics.com";
            config.useHTTPSAlways = true;
            config.resTiming = {"bufSize":200,"clearResTimingOnBeaconSend":true};
            config.maxUrlLength = 512;
            config.spa = {"spa2":true};
        })(window["adrum-config"] || (window["adrum-config"] = {}));
    `;
    document.head.appendChild(adrumConfigScript);
    // Second script: nonce configuration
    const nonceConfigScript = document.createElement("script");
    nonceConfigScript.type = "text/javascript";
    nonceConfigScript.charset = "UTF-8";
    nonceConfigScript.nonce = nonce;
    nonceConfigScript.innerHTML = `
        (function (config) {
            config.elementIdWithNonce = "adrumScript";
        })(window['adrum-config'] || (window['adrum-config'] = {}));
    `;
    document.head.appendChild(nonceConfigScript);
    // Third script: adrum script
    const adrumScript = document.createElement("script");
    adrumScript.src = "//cdn.appdynamics.com/adrum/adrum-latest.js";
    adrumScript.type = "text/javascript";
    adrumScript.charset = "UTF-8";
    adrumScript.id = "adrumScript";
    adrumScript.nonce = nonce;
    adrumScript.async = true;
    document.head.appendChild(adrumScript);
    // Cleanup scripts on unmount
  }, []);
  return (
    <ErrorBoundary FallbackComponent={ErrorFallbackComponent}>
      <GloblaContextProvider>
        <ThemeProvider theme={theme}>
          <ToastSnackbar />
          <Index />
        </ThemeProvider>
      </GloblaContextProvider>
    </ErrorBoundary>
  );
}

export default App;
